import { TextField, Box, Dialog, IconButton, FormControl, Button } from '@mui/material'
import { useState } from 'react'
import styled from '@emotion/styled';
import theme from '../../theme';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SaveIcon from '@mui/icons-material/Save';

function EditDocument({ data }) {
  const [editOpen, setEditOpen] = useState(false);
  const [body, setBody] = useState(data);
  const [zoom, setZoom] = useState(false);

  return (
    <Box>
      <EditIcon onClick={() => setEditOpen(true)} />
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        fullScreen={zoom}
        fullWidth
        maxWidth="xl"
      >
        <ButtonBox>
          <StyledButton onClick={() => setZoom(!zoom)}>
            {zoom ? <ZoomOutIcon /> : <ZoomInIcon />}
          </StyledButton>
          <StyledButton onClick={() => setEditOpen(false)}>
            <CloseIcon />
          </StyledButton>
        </ButtonBox>
        <Box>
          <FormControl fullWidth>
            <StyledTextField
              id="editHTML"
              label="Bisheriger Text"
              multiline
              maxRows={25}
              variant='outlined'
              value={body}
            />
          </FormControl>
        </Box>
        <ButtonBox>
          <Button variant='outlined' onClick={() => setEditOpen(false)}>
            <CloseIcon /> Abbrechen
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setEditOpen(false)}>
            <SaveIcon /> Speichern
          </Button>
        </ButtonBox>
      </Dialog>
    </Box>
  )
}

export default EditDocument


// Styling


const StyledButton = styled(IconButton)(() => ({
  color: theme.palette.black,
  background: `${theme.palette.stone}44`,
  transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
    background: `${theme.palette.stone}ff`
  }
}));

const StyledTextField = styled(TextField)(() => ({
  margin: "0 20px",
  minWidth: "80%",
  "& .MuiPaper-root": {
    width: "100%",
  }
}));

const ButtonBox = styled(Box)(() => ({
  padding: "30px 100px 30px 30px",
  display: "flex",
  alignItems: "center",
  gap: 30,
  justifyContent: "right"
}));

