import { Download, PlayArrow } from '@mui/icons-material'
import { Container, Box, Button, Typography, Stack, Link } from '@mui/material'
import React from 'react'
import { sendToBackend } from '../../helper/websocketHelper'

export default function AdminIncomes({ data }) {
  // console.log("incomes", data)

  return (
    <Container>
      <Typography variant="h2">Einkommensabgabe</Typography>
      <Typography textAlign="center" variant="h5" sx={{ padding: "30px 0" }}>Bisher wurden {data.interaction.submitted.value} von {data.interaction.total.value} Einkommen abgegeben.</Typography>
      <Stack direction="row" spacing={3} sx={{ width: "100%" }} justifyContent="space-around" alignItems="center">
        <Stack direction="column" spacing={3}>
          <Box>
            <Stack direction="column" spacing={3}>
              <Button startIcon={<PlayArrow />} disabled={!data?.interaction.start.enabled} variant="contained" color="warning" onClick={() => sendToBackend("start")}>Verfahren starten</Button>
              {data.interaction.mail_queue.value > 0 && <Typography>Noch {data.interaction.mail_queue.value} E-Mails werden verschickt.</Typography>}
              {/* <Button startIcon={<PlayArrow />} variant="contained" color="warning" onClick={() => sendToBackend("delete")}>Pseudonyme löschen</Button> */}
            </Stack>
          </Box>
        </Stack>
        <Stack direction="column" spacing={3}>
          <Button component={Link} startIcon={<Download />} variant="contained" color="primary" href="/csv/income/pseudonyms" >Wer hat bisher nicht abgegeben?</Button>
          <Button component={Link} href="/csv/income/entries" disabled={data.interaction.submitted.value < 10} startIcon={<Download />} variant="contained" color="primary">abgegebene Einkommen</Button>
        </Stack>
      </Stack>
    </Container>
  )
}
