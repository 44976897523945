import { Warning } from "@mui/icons-material";
import { sendToBackend } from "../../helper/websocketHelper";
import { Button, Typography, Stack, Box } from "@mui/material"
import CurrencyInput from "react-currency-input-field";

export const Income = ({ data }) => {
  //console.log("income", data)

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget).get("income")

    if (data.indexOf(",") === data.length - 1 || data.indexOf(",") === -1) {
      data = data + ",00"
    } else if (data.indexOf(",") === data.length - 2) {
      data = data + "0"
    }

    const cents = data.replace(/\./g, "").replace(/€ /g, "").replace(/,/g, "");
    sendToBackend("submit", { cents: parseInt(cents) });
  };

  return (
    <>
      <Typography variant="h1" color="primary" textAlign="center">Einkommensabgabe</Typography>
      <Box sx={{ padding: 2, maxWidth: "60%", border: "2px solid black", margin: "0 auto 20px" }}>
        <Stack justifyContent="center" alignItems="center" sx={{ padding: 2 }} direction="row">
          <Warning color="warning" sx={{ fontSize: "3rem" }} />

          <Typography variant="h5" textAlign="center">Bitte notiere dir dein eingegebenes Einkommen. Es wird anonymisiert gespeichert und kann nicht eingesehen werden!</Typography>
          <Typography variant="h5" textAlign="center">Please note your income, it is not possible to check later what you filled in here!</Typography>
        </Stack>
      </Box>
      <Typography textAlign="center" sx={{ padding: 2 }}>{data.interaction.submit.has_submitted_income ? "Du hast dein, bzw. euer Einkommen schon abgegeben." : "Auf dieser Seite kannst du dein, bzw. euer monatliches Einkommen abgeben."}</Typography>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <CurrencyInput
            id="income"
            name="income"
            placeholder="Einkommen"
            defaultValue={data.interaction.submit.has_submitted_income ? data.interaction.submit.income : ""}
            decimalsLimit={2}
            prefix="€ "
            groupSeparator="."
            decimalSeparator=","
            allowNegativeValue={false}
            autoFocus={true}
            required
          />
          <Button variant="contained" type="submit" color="primary">{data.interaction.submit.has_submitted_income ? "korrigieren" : "abschicken"}</Button>
        </Stack>
      </form>

      <Box sx={{ padding: 2 }}>
        <Typography>//english version below//</Typography>
        <Typography variant="h2">Wie funktionierts?</Typography>

        <Typography variant="h5">Schritt 1:</Typography>
        <Typography>Jede Person aus eurer Mitgliedschaft, die vom GaCo-Gemüse isst, ermittelt ihr eigenes monatliches Netto-Einkommen (siehe Hinweis unten "Was zählt ins Nettoeinkommen").</Typography>

        <Typography variant="h5">Schritt 2:</Typography>
        <Typography>Bildet die Summe aus den einzelnen Netto-Einkommen, also das Gesamteinkommen des Haushalts.</Typography>

        <Typography variant="h5">Schritt 3:</Typography>
        <Typography>Ermittelt den Gewichtungsfaktor für die Personenanzahl eurer Mitgliedschaft: Eine Person in der Mitgliedschaft wird mit Faktor 1 gewichtet, alle weiteren mit 0,5 (siehe Hinweise unten zum Gewichtungsfaktor und Haushalten).</Typography>
        <Typography>Beispiele:<br />
          Ihr seid 2 Personen in der Mitgliedschaft. Eine Person hat den Faktor 1, die andere Person hat den Faktor 0,5, zusammengerechnet ergibt das 1,5:
          1 + 0,5 = 1,5<br />
          Ihr seid 4 Personen in der Mitgliedschaft. Eine Person hat den Faktor 1, die anderen drei haben den Faktor 0,5, zusammengerechnet ergibt das 2,5:
          1 + 0,5 + 0,5 + 0,5 = 2,5
        </Typography>
        <Typography variant="h5">Schritt 4:</Typography>
        <Typography>Teilt die Summe eurer Netto-Einkommen aus Schritt 2 durch euren Gewichtungsfaktor aus Schritt 3. Das ist eurer Durchschnitt-Haushalt-Netto-Einkommen, dass ihr auf dieser Website eingebt (die Anzahl eurer Anteile wird automatisch berücksichtigt).</Typography>

        <Typography>Hier findet ihr eine Rechenhilfe für die Ermittlung eures Einkommens (runterscrollen zu Dateien: "Berechnung_Einkommen"):</Typography>
        <Typography><a href="https://www.gartencoop.org/tunsel/node/7567" target="_blank" rel="noreferrer">www.gartencoop.org/tunsel/node/7567</a>.</Typography>

        <Typography>Dort findet ihr auch einen Flyer sowie mehr Informationen zum neuen Bietverfahren und zum Prozess, wie dieses entstanden ist, findet ihr hier </Typography>

        <Typography variant="h5">Was zählt ins Nettoeinkommen?</Typography>
        <ul>
          <li>Netto-Lohn/ Gehalt (auch Bonuszahlungen, Weihnachtsgeld o.Ä.)</li>
          <li>Ersatzleistungen, andere staatliche Leistungen (z.B. Kindergeld, Bürgergeld, etc.)</li>
          <li>Netto-Einkommen aus selbständiger Arbeit nach Abzug von Versicherung, Steuern etc.</li>
          <li>Unterhaltszahlungen</li>
          <li>Kapitalerträge</li>
          <li>Mieteinnahmen</li>
          <li>Rente/ Pension/ …</li>
        </ul>

        <Typography variant="h5">Gewichtungsfaktor:</Typography>
        <Typography>Der Grund für den Gewichtungsfaktor: Menschen, die in einem Haushalt leben, sparen tendenziell Kosten im Vergleich zu einem 1-Personen-Haushalt (Miete für Gemeinschaftsräume, Geräte etc.), deshalb wird ab der zweiten Person mit 0,5 gerechnet (in Anlehnung an das Netto-Äquivalenz-Einkommen der OECD).<br />
          Vielfältige Wohnsituationen und Mitgliedschaftszusammensetzungen werden der Einfachheit halber bei der Berechnung zunächst alle gleich behandelt - können aber bei der Selbsteinschätzung des Gebots einfließen.</Typography>

        <Typography variant="h5">Haushalt:</Typography>
        <Typography>Als Mitglied eines Haushalts gelten alle Menschen, die gemeinsam eine Mitgliedschaft haben und sich das GaCo-Gemüse teilen (egal ob sie zusammen wohnen oder nicht). Auch Kinder gelten als Haushaltsmitglieder.</Typography>

        <Typography variant="h5">Datenschutz:</Typography>
        <Typography>Jede Mitgliedschaft erhält ein Link mit einem Pseudonym, mit dem das Einkommen auf derWebsite hinterlegt werden kann. Auf der Webseite werden die Pseudonyme ohne Zuordnung zur Mitgliedschaft abgespeichert. Das Einkommen kann später nicht mehr eingesehen werden. Durch Eingabe eines neuen Betrags, kann das Einkommen später allerdings noch korrigiert werden.<br />
          Zu jedem Pseudonym ist abgespeichert, wie viele Anteile die Mitgliedschaft dahinter erhält. D.h. es kann nachvollzogen werden, ob ein abgegebenes Einkommen zu einer Mitgliedschaft mit ganzem oder halben Anteil gehört.<br />
          Für das Büroteam ist einsehbar, ob und welche Mitgliedschaft ihr Einkommen schon abgegeben hat, nicht aber die Höhe des Einkommens.<br />
          Ab 10 abgegebenen Einkommen kann die Liste der Einkommen (ohne Zuordnung zur Mitgliedschaft) vom Büroteam heruntergeladen werden. Hiermit wird ein Prozentsatz ermittelt, der (ab kommenden Jahr) als Richtwert für die eigentliche Bietrunde verwendet wird.<br />
          Die Zuordnung der Pseudonyme zu Mitgliedschaften ist zu keinem Zeitpunkt auf dem Server abgespeichert.</Typography>

        <Typography>Wenn ihr nach den Erklärungen noch Fragen habt, schreibt eine Mail an: <a href="mailto:bietverfahren@gartencoop.org">bietverfahren@gartencoop.org</a></Typography>
        <Typography variant="h5">Video-Erklärung gibts hier:</Typography>
        <video src="/GaCo-Bietverfahren-Video_Testlauf.m4v" width="750" height="500" controls></video>
      </Box>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h2">english version:</Typography>

        <Typography variant="h5">Step 1:</Typography>
        <Typography>Every person that is part of your membership and eats the GaCo-veggies, calculates their own monthly net income.<br />
          (see explanation below: "What is included in the net-income?").</Typography>

        <Typography variant="h5">Step 2:</Typography>
        <Typography>Add all the individual net incomes, the sum is the net income of your household.</Typography>

        <Typography variant="h5">Step 3:</Typography>
        <Typography>Sum up the weighting factor for the number of people in your household. One person is counted with factor 1, all other people with factor 0,5 (see explanations below: "weighting factor" and "household")</Typography>

        <Typography>Examples:<br />
          You are 2 people that share a membership. One person counts with a factor of 1, the other of 0,5, which makes 1,5 in total.
          1 + 0,5 = 1,5<br />
          You are 4 people that share a membership. One person counts with a factor of 1, the three others with 0,5 each, that makes 2,5 in total.
          1 + 0,5 + 0,5 + 0,5 = 2,5</Typography>

        <Typography variant="h5">Step 4:</Typography>
        <Typography>Divide the net income of your household (from step 2) by the weighting factor (from step 3). The result is the average net income of your household that you fill in on this website.<br />
          The amount of shares you have is being considered automatically.</Typography>

        <Typography>You will find a pdf-document which can help you with the calculation of your income on the website ("Berechnung_Einkommen"):</Typography>
        <Typography><a href="https://www.gartencoop.org/tunsel/node/7567" target="_blank" rel="noreferrer">www.gartencoop.org/tunsel/node/7567</a>.</Typography>

        <Typography>On this site, you will also find a flyer with more information on this new procedure and the reasons for it, more details on the new concept and the process we went through to develop it.</Typography>

        <Typography variant="h5">What is included in the net income?</Typography>
        <ul>
          <li>net wage (including bonuses, e.g. christmas bonus or others)</li>
          <li>government benefits, social security, public services (e.g. child benefit, housing help, Bürgergeld, etc.) </li>
          <li>net income from self-employment (less taxes, social insurance contributions)</li>
          <li>alimony</li>
          <li>yield on capital</li>
          <li>rental incomes</li>
          <li>pension</li>
        </ul>
        <Typography variant="h5">Weighting factor:</Typography>
        <Typography>Why use a weighting factor? Persons that share a household tend to reduce costs compared to a single household (rent for shared rooms, household appliances etc.)
          That's why every additional person is counted with a factor of 0,5 instead of 1 (similar to the “OECD equivalence scale").<br />
          For simplicity we treat the diverse possible situations of how people live together or share a membership the same way for the calculation of the new orientation value, that we will use from next year on. They can still be considered individually when the membership contribution is decided upon.</Typography>

        <Typography variant="h5">Household:</Typography>
        <Typography>Everyone who shares one membership and therefor the GaCo-veggies are considered one household (no matter whether they live together or not). Kids are also counted as members of the household.</Typography>

        <Typography variant="h5">Data privacy:</Typography>
        <Typography>Every membership gets a personalized link with a pseudonym, where they fill in their income on the website. The pseudonyms are stored on the website with no information on the actual membership. It is not possible to see the income after submitting it. It can however be modified by submitting a new value. The information on how many shares one membership has, is stored on the website linked to the pseudonym. That means it is possible to find out if the income is for half a share or an entire share. The support team of the Gartencoop can see which memberships already submitted their income but cannot see the concrete amount. When at least 10 memberships submitted their income, the support team can download a list of the incomes (without correlation between income and individual membership). This list will be the base to calculate the percentage which is going to be the orientation value for the pay-what-you-can round (not for the Test-run this year, but from 2024 on). At no moment the correlation pseudonym to membership will be recorded on the server.</Typography>

        <Typography>If you have any questions contact us: <a href="mailto:bietverfahren@gartencoop.org">bietverfahren@gartencoop.org</a></Typography>

        <Typography variant="h5">video-tutorial (in german)</Typography>
        <video src="/GaCo-Bietverfahren-Video_Testlauf.m4v" width="750" height="500" controls></video>
      </Box>
    </>
  )
}
